import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import Page from "../../components/Page";
import SEO from "../../components/SEO";

import { advertisers } from "../../helpers/navigation";

const SolutionsPage = () => {
  const data = useStaticQuery(graphql`
    query {
      img: file(relativePath: { eq: "adv/Img_Advertisers_Solution.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);

  return (
    <Page
      page="Solutions"
      pageName="Рішення"
      title="Мережа інформаційно-рекламних моніторів"
      parameters={advertisers}
    >
      <SEO title="Рішення" />
      <div className="Page_main_block">
        <Img
          fluid={data.img.childImageSharp.fluid}
          className="Page_main_block_img-right"
        />
      </div>
      <div className="Page_main_block">
        <p>
          <strong>ДІВСервіс</strong> розміщує <br />
          <strong>монітори високої роздільної здатності</strong>
          <br /> в приліфтових приміщеннях будинків
        </p>
        <p>
          <strong>ДІВСервіс</strong> надає <br />
          якісний повноцінний сервіс з <br />
          <strong>виготовлення, розміщення та оновлення</strong>
          <br /> інформаційних оголошень
        </p>
      </div>
    </Page>
  );
};

export default SolutionsPage;
